import { t } from "@/i18n-js/instance";
import type {
  SpaceAccessDetails,
  SpaceGroupAccessDetails,
} from "@circle-react/types/AccessRegistry";
import { Accordion } from "./Accordion";

export interface ContentSpecAccessGroupsProps {
  contentSpecAccessDetails: SpaceGroupAccessDetails | SpaceAccessDetails;
}

const i18nRoot =
  "settings.access_registry.components.content_spec_access_groups";

export const ContentSpecAccessGroups = ({
  contentSpecAccessDetails,
}: ContentSpecAccessGroupsProps) => {
  const accessGroupsCount = contentSpecAccessDetails.access_groups.length;
  if (accessGroupsCount === 0) {
    return null;
  }

  return (
    <Accordion
      title={t([i18nRoot, "title"], {
        count: accessGroupsCount,
      })}
      defaultExpanded={accessGroupsCount <= 2}
    >
      <Accordion.BulletedList
        items={contentSpecAccessDetails.access_groups.map(
          accessGroup => accessGroup.name,
        )}
      />
    </Accordion>
  );
};
