import { useEffect } from "react";
import { flatten, isFunction } from "lodash";
import { useInfiniteQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@circle-react/api/eventApi";
import {
  Filter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { SingleSelectFilterForm } from "./SingleSelectFilterForm";

const localeNamespace = "members_directory.header.search.filters.events";

interface EventsFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: BaseFilter | null) => void;
  param: BaseFilter;
}

export const EventsFilter = ({
  isInitialOpen,
  onApply,
  param,
}: EventsFilterProps) => {
  const fetchPage = ({ pageParam = 1 }) =>
    eventApi.listEvents({
      params: {
        page: pageParam,
      },
    });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["events"],
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);

  const generateCheckboxData = (record: any) => ({
    richLabel: (
      <>
        <Typography.LabelMd
          as="div"
          weight="medium"
          color="text-selector-active"
        >
          {record.name}
        </Typography.LabelMd>
        <Typography.LabelSm color="text-default">
          {record.start_date}
        </Typography.LabelSm>
      </>
    ),
    label: `${record.name} - ${record.start_date}`,
    value: record.id.toString(),
  });

  let events = flatten(data?.pages.map(page => page.records));
  const pastEvents = events
    .filter(record => !record.upcoming_event)
    .map(record => generateCheckboxData(record));
  const upcomingEvents = events
    .filter(record => record.upcoming_event)
    .map(record => generateCheckboxData(record));
  events = events.map(record => generateCheckboxData(record));

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={events} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!events?.length && (
            <div className="flex h-28 items-center justify-center p-4">
              <Typography.LabelSm>
                {t([localeNamespace, "no_events"])}
              </Typography.LabelSm>
            </div>
          )}
          {(!!upcomingEvents?.length || !!pastEvents?.length) && (
            <SingleSelectFilterForm
              upcomingEvents={upcomingEvents}
              pastEvents={pastEvents}
              legend={t([localeNamespace, "singular_legend"])}
              param={param}
              searchProps={{
                placeholder: t([localeNamespace, "search_placeholder"]),
              }}
            />
          )}
        </>
      )}
    </Filter>
  );
};
