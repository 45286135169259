import { useCallback, useState } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface ContainerProps {
  title: string;
  collapsible?: boolean;
  defaultExpanded?: boolean;
  children: React.ReactNode;
}

// TODO: add some smooth transition when expanding/collapsing
export const Container = ({
  title,
  collapsible = true,
  defaultExpanded = false,
  children,
}: ContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggle = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

  return (
    <div className="border-primary flex flex-col rounded-lg border">
      <div
        className={classNames(
          "bg-secondary border-primary flex flex-row items-center justify-between rounded-t-lg px-4 py-2 align-middle",
          {
            "rounded-b-lg": collapsible && !isExpanded,
            "border-b": !collapsible || isExpanded,
          },
        )}
      >
        <Typography.LabelXxs weight="semibold">{title}</Typography.LabelXxs>
        {collapsible && (
          <Button
            type="button"
            variant="tertiary"
            className="!p-0"
            onClick={toggle}
          >
            <Icon
              type={isExpanded ? "16-chevron-down" : "16-chevron-right"}
              size={16}
              viewBox="0 0 20 20"
            />
          </Button>
        )}
      </div>
      {(!collapsible || isExpanded) && children}
    </div>
  );
};
