import classNames from "classnames";
import copyToClipboard from "copy-to-clipboard";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { ChatIcon } from "@circle-react/components/Chat/ChatIcon";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import {
  chatRoomPath,
  communityMemberProfilePath,
  getRouteWithPrevLocation,
} from "@circle-react/helpers/urlHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import {
  useAnyHoverMediaQuery,
  useSmScreenMediaQuery,
} from "@circle-react/hooks/useMediaQuery";
import type { CommunityMember } from "@circle-react/types";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface MemberActionsProps {
  communityMember: CommunityMember;
}

export const MemberActions = ({ communityMember }: any) => {
  const { currentCommunityMember } = usePunditUserContext();
  const canHover = useAnyHoverMediaQuery();
  const { showOwnProfileEdit, showMemberProfileEdit } = useShowProfileEdit();
  const { findOrCreateChatRoom } = useChatRoom();
  const history = useHistory();
  const { success } = useToast();

  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const isCurrentUser = currentCommunityMember?.id === communityMember.id;
  const isSmScreen = useSmScreenMediaQuery();
  const profilePath = communityMemberProfilePath({
    public_uid: currentCommunityMember.public_uid,
  });

  const shouldShowChatButton =
    !isCurrentUser &&
    communityMember.messaging_enabled &&
    currentCommunityMember?.messaging_enabled;

  const onClickSendMessage = async () => {
    const chatRoomUuid = await findOrCreateChatRoom({
      kind: DIRECT_CHAT_ROOM,
      community_member_ids: [communityMember.id],
    });

    history.push(
      getRouteWithPrevLocation({ url: chatRoomPath({ uuid: chatRoomUuid }) }),
    );
  };

  const onClickCopyProfileLink = () => {
    copyToClipboard(window.location.origin + profilePath);
    success(t("post.share.link_copied"), {
      duration: "short",
      shouldUseProgress: false,
    });
  };

  const onClickEdit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (isCurrentUser) {
      return showOwnProfileEdit();
    }
    showMemberProfileEdit({
      memberPublicId: communityMember.public_uid,
    });
  };

  return (
    <div
      className={classNames(
        "text-default hover:text-default flex items-center gap-x-1",
        canHover && "invisible group-hover:visible",
      )}
    >
      {shouldShowChatButton && !isSmScreen && (
        <ChatIcon showIcon communityMemberId={communityMember.id} />
      )}
      {isAdmin && (
        <Dropdown
          button={
            <IconButton
              name="16-menu-dots-horizontal"
              iconClassName="!text-dark"
              ariaLabel={t("community_member.actions")}
            />
          }
        >
          {isSmScreen && (
            <Dropdown.ItemWithLink onClick={onClickCopyProfileLink}>
              {t("post.share.copy_link_to_profile")}
            </Dropdown.ItemWithLink>
          )}
          {isSmScreen && shouldShowChatButton && (
            <Dropdown.ItemWithLink onClick={onClickSendMessage}>
              {t("message")}
            </Dropdown.ItemWithLink>
          )}
          <Dropdown.ItemWithLink onClick={onClickEdit}>
            {t("edit")}
          </Dropdown.ItemWithLink>
        </Dropdown>
      )}
    </div>
  );
};
