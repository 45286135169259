import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";

const localeNamespace = "members_directory.header.search.filters";

interface LocationFilterProps {
  isInitialOpen?: boolean;
  onApply?: (param: StringComparatorParam) => void;
  param: StringComparatorParam;
}

export const LocationFilter = ({
  isInitialOpen,
  onApply,
  param,
}: LocationFilterProps) => (
  <Filter
    chip={t([localeNamespace, "location"])}
    title={t([localeNamespace, "location_title"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    renderSelectedText={() => renderTextSelectedItem(param)}
    onApply={(value: StringComparatorParam) => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <InputFilterComparator
      name="location"
      param={param}
      placeholder={t([localeNamespace, "location_placeholder"])}
    />
  </Filter>
);
