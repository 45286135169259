import { create } from "zustand";
import type { NodeTypes, WorkflowStore } from "./types";

export const useWorkflowStore = create<WorkflowStore>((set, get) => ({
  isOpen: false,
  toggle: () => {
    set({ isOpen: !get().isOpen });
  },

  nodeType: null,
  setNodeType: type => set({ nodeType: type }),
  setInitialNodeTypeAndIsOpen: (type, isOpen = true) =>
    set({ nodeType: type, isOpen }),
  isNodeSelectedOfType: (type: NodeTypes) => get().nodeType === type,

  actionParams: null,
  setActionParams: params => set({ actionParams: params }),

  selectedEdgeId: null,
  setSelectedEdgeId: id => set({ selectedEdgeId: id }),

  isUpdatingBaseNode: false,
  setIsUpdatingBaseNode: isUpdating => set({ isUpdatingBaseNode: isUpdating }),

  actionNodeUpdateParams: { nodeId: undefined, isUpdating: false },
  setActionNodeUpdateParams: params => set({ actionNodeUpdateParams: params }),

  resetCanvasState: () =>
    set(state => ({
      ...state,
      selectedEdgeId: null,
      actionParams: null,
      nodeType: null,
    })),
}));

export const useDrawerState = () => {
  const isDrawerOpen = useWorkflowStore(state => state.isOpen);
  const toggleDrawerOpen = useWorkflowStore(state => state.toggle);
  return { isDrawerOpen, toggleDrawerOpen };
};

export const useNodeType = () => {
  const nodeType = useWorkflowStore(state => state.nodeType);
  const setNodeType = useWorkflowStore(state => state.setNodeType);
  const isNodeSelectedOfType = useWorkflowStore(
    state => state.isNodeSelectedOfType,
  );
  return { nodeType, setNodeType, isNodeSelectedOfType };
};

export const useActionParams = () => {
  const actionParams = useWorkflowStore(state => state.actionParams);
  const setActionParams = useWorkflowStore(state => state.setActionParams);
  return { actionParams, setActionParams };
};

export const useSelectedEdge = () => {
  const selectedEdgeId = useWorkflowStore(state => state.selectedEdgeId);
  const setSelectedEdgeId = useWorkflowStore(state => state.setSelectedEdgeId);
  return { selectedEdgeId, setSelectedEdgeId };
};

export const useBaseNodeUpdatingState = () => {
  const isUpdatingBaseNode = useWorkflowStore(
    state => state.isUpdatingBaseNode,
  );
  const setIsUpdatingBaseNode = useWorkflowStore(
    state => state.setIsUpdatingBaseNode,
  );
  return { isUpdatingBaseNode, setIsUpdatingBaseNode };
};

export const useActionNodeUpdateParams = () => {
  const actionNodeUpdateParams = useWorkflowStore(
    state => state.actionNodeUpdateParams,
  );
  const setActionNodeUpdateParams = useWorkflowStore(
    state => state.setActionNodeUpdateParams,
  );
  return { actionNodeUpdateParams, setActionNodeUpdateParams };
};

export const useResetCanvasState = () =>
  useWorkflowStore(state => state.resetCanvasState);

export const useSetInitialNodeTypeAndIsOpen = () =>
  useWorkflowStore(state => state.setInitialNodeTypeAndIsOpen);
