import { t } from "@/i18n-js/instance";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const WorkflowLinks = () => {
  const links: Link[] = [
    {
      label: t("settings_nav_v3.workflows.all"),
      path: settingsNavPath.workflows.all,
      exact: true,
    },
    {
      label: t("settings_nav_v3.workflows.history"),
      path: settingsNavPath.workflows.history,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.workflows.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} label={link.label} path={link.path} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
