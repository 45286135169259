import { useState } from "react";
import { t } from "@/i18n-js/instance";

export const ButtonBasedPagination = ({ initialPage = 2, fetchNewPage }) => {
  const [page, setPage] = useState(initialPage);

  const incrementPage = () => {
    setPage(page + 1);
  };

  const sendRequest = () => {
    fetchNewPage(page);
    incrementPage();
  };

  return (
    <div className="pagination-load-more">
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => sendRequest()}
      >
        {t("show_more_button")}
      </button>
    </div>
  );
};
