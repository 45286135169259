import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder.recipient_modal";

interface LinkClickedRecipientListProps {
  link?: string;
  count: number;
  formattedCount: string;
}

export const LinkClickedRecipientList = ({
  link,
  count,
  formattedCount,
}: LinkClickedRecipientListProps) => (
  <div className="gap-y-0.5 px-8">
    <div className="my-2 w-3/4 truncate">
      <Typography.LabelMd weight="medium" color="text-default">
        {link}
      </Typography.LabelMd>
    </div>
    <div className="pb-1 pt-5">
      <Typography.LabelMd weight="semibold" color="text-very-dark">
        {t([localeNamespace, "x_contacts"], {
          count,
          formattedCount,
        })}
      </Typography.LabelMd>
    </div>
  </div>
);
