import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";

const localeNamespace = "members_directory.header.search.filters";

interface NameFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: StringComparatorParam) => void;
  param: StringComparatorParam;
}

export const NameFilter = ({
  isInitialOpen,
  onApply,
  param,
}: NameFilterProps) => (
  <Filter
    chip={t([localeNamespace, "name"])}
    title={t([localeNamespace, "name_title"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    renderSelectedText={() => renderTextSelectedItem(param)}
    onApply={(param: StringComparatorParam) => {
      if (isFunction(onApply)) {
        onApply(param);
      }
    }}
    shouldShowRemoveButton
  >
    <InputFilterComparator
      name="name"
      param={param}
      placeholder={t([localeNamespace, "name_placeholder"])}
    />
  </Filter>
);
