import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { useSpacesForFilters } from "@/react/components/Spaces/hooks/useSpacesForFilters";
import { useSpacesContext } from "@circle-react/contexts";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.spaces";

interface SpacesFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

export const SpacesFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: SpacesFilterProps) => {
  const { helpers } = useSpacesContext();
  const spaces = useSpacesForFilters({
    spaces: helpers.spaceWithViewContentAccess(),
  });

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={spaces} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {helpers.isDataLoading() ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={spaces}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
