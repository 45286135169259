import { t } from "@/i18n-js/instance";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const PlansLinks = () => {
  const { isCommunityOnPlus } = usePlanStatus();
  const { currentCommunity } = usePunditUserContext();
  const { currentCommunitySettings } = usePunditUserContext();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.plans.billing_history"),
      path: settingsNavPath.plans.billing,
      exact: true,
      isDisabled:
        isCommunityOnPlus || !currentCommunity?.has_subscription_record,
    },
    {
      label: isCommunityOnPlus
        ? t("settings_nav_v3.plans.billing_details")
        : t("settings_nav_v3.plans.change_plan"),
      path: settingsNavPath.plans.general,
      exact: true,
    },
    {
      label: t("settings_nav_v3.plans.branded_apps"),
      path: settingsNavPath.plans.brandedApps,
      exact: true,
      isDisabled:
        isCommunityOnPlus ||
        !currentCommunitySettings?.circle_plus_banners_enabled,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content
        title={
          isCommunityOnPlus
            ? t("settings_nav_v3.plans.plus_label")
            : t("settings_nav_v3.plans.label")
        }
      >
        {links.map(link => (
          <SubNavLinkItem
            key={link.path}
            label={link.label}
            path={link.path}
            isDisabled={link.isDisabled}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
