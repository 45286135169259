import { useSegments } from "@circle-workflows/hooks/useSegments";
import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.segments";

interface SegmentFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

export const SegmentFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: SegmentFilterProps) => {
  const { segments, isLoading } = useSegments();
  const segmentItems = segments?.map(segment => ({
    label: segment.title,
    value: segment.id.toString(),
  }));

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={segmentItems} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={segmentItems || []}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
