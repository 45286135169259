import { t } from "@/i18n-js/instance";
import { PAYWALL_COUPON_STATUS } from "@circle-react/helpers/paywallCouponHelpers";
import { Typography } from "@circle-react-uikit/Typography";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.subscriptions.subscription_details_modal";

export const CouponField = ({ subscription }: any) => {
  const paywallCouponRedemption = subscription?.paywall_coupon_redemption;
  const paywallCoupon = paywallCouponRedemption?.paywall_coupon;

  const couponCodeString =
    paywallCouponRedemption?.status === PAYWALL_COUPON_STATUS.ACTIVE
      ? `${paywallCoupon?.name} / ${paywallCouponRedemption?.paywall_coupon?.terms}`
      : t(`${localeStringRoot}.no_coupon_applied`);

  return (
    <div>
      <div>
        <Typography.LabelSm weight="semibold">
          {t(`${localeStringRoot}.labels.coupon_applied`)}
        </Typography.LabelSm>
      </div>
      <Typography.LabelSm>{couponCodeString}</Typography.LabelSm>
    </div>
  );
};
