import { t } from "@/i18n-js/instance";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsInAppEnabled } from "@circle-react/hooks/useIsInAppEnabled";
import "@circle-react-shared/PlusUpgradeBanners/shared/BannerBackgrounds.css";
import { BannerComponent } from "./BannerComponent";

interface BrandSettingsBannerProp {
  title?: string;
  onClick?: () => void;
  className?: string;
}

const defaultOnClick = () => {
  window.open(settingsNavPath.plans.brandedApps, "_blank");
};

export const Banner = (prop: BrandSettingsBannerProp = {}) => {
  const { isCommunityOnPlus } = usePlanStatus();
  const isInAppEnabled = useIsInAppEnabled();

  if (isCommunityOnPlus && isInAppEnabled) {
    return null;
  }

  if (isCommunityOnPlus && !isInAppEnabled) {
    return (
      <BannerComponent
        {...prop}
        buttonText={t("get_started")}
        onClick={undefined}
      />
    );
  }

  return <BannerComponent {...prop} onClick={defaultOnClick} />;
};
