import { t } from "@/i18n-js/instance";
import { toLocaleString } from "@circle-react/helpers/number";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Table } from "@circle-react-shared/uikit/TableV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useBroadcastReport } from "../BroadcastReportContext";
import { ViewRecipientListButton } from "./ViewRecipients/ViewRecipientListButton";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.links_table";

export interface LinkClickRow {
  link: string;
  unique_clicks: number;
  clicks: number;
  link_type: string;
  link_text: string;
  link_label: string;
}

export const LinkClickStatsTable = () => {
  const { data: { link_clicks: linkClicks = [] } = {} } = useBroadcastReport();
  const shouldShowActionsColumn = linkClicks.some(
    linkClick => linkClick.clicks > 0,
  );

  return (
    <Table className="w-min table-fixed">
      <Table.TableHead>
        <Table.Tr>
          <Table.Th className="border-b-0 pb-0">
            <Table.TableHeaderText>
              {t([localeNamespace, "columns", "name"])}
            </Table.TableHeaderText>
          </Table.Th>
          <Table.Th className="w-[150px] border-b-0 pb-0 text-right">
            <Table.TableHeaderText className="!block">
              {t([localeNamespace, "columns", "unique_clicks"])}
            </Table.TableHeaderText>
          </Table.Th>
          <Table.Th className="w-[150px] border-b-0 pb-0 text-right">
            <Table.TableHeaderText className="!block">
              {t([localeNamespace, "columns", "total_clicks"])}
            </Table.TableHeaderText>
          </Table.Th>
          {shouldShowActionsColumn && (
            <Table.Th className="w-[150px] border-b-0 pb-0 text-right">
              <Table.TableHeaderText />
            </Table.Th>
          )}
        </Table.Tr>
      </Table.TableHead>
      <Table.TableBody>
        {linkClicks.map((linkClicksRow: LinkClickRow) => (
          <Table.Tr key={linkClicksRow.link}>
            <Table.Td className="truncate border-b-0 !py-2.5 pb-0">
              <TippyV2 content={linkClicksRow.link} placement="top-start">
                <Typography.LabelSm weight="regular" color="text-default">
                  {linkClicksRow.link_label || linkClicksRow.link_text}
                </Typography.LabelSm>
              </TippyV2>
            </Table.Td>
            <Table.Td className="border-b-0 !py-2.5 pb-0 text-right">
              <Typography.LabelSm weight="medium" color="text-default">
                {toLocaleString(linkClicksRow.unique_clicks)}
              </Typography.LabelSm>
            </Table.Td>
            <Table.Td className="border-b-0 !py-2.5 pb-0 text-right">
              <Typography.LabelSm weight="medium" color="text-default">
                {toLocaleString(linkClicksRow.clicks)}
              </Typography.LabelSm>
            </Table.Td>
            {shouldShowActionsColumn && (
              <Table.Td className="border-b-0 !py-2.5 pb-0 text-right">
                {linkClicksRow.clicks > 0 && (
                  <ViewRecipientListButton
                    recipient_type="link_clicks_recipients"
                    link={linkClicksRow.link}
                    className="text-dark inline-block decoration-current decoration-dashed underline-offset-2"
                  >
                    <Typography.LabelSm weight="semibold" color="text-default">
                      {t([localeNamespace, "view_recipients"])}
                    </Typography.LabelSm>
                  </ViewRecipientListButton>
                )}
              </Table.Td>
            )}
          </Table.Tr>
        ))}
      </Table.TableBody>
    </Table>
  );
};
