import InnerHTML from "dangerously-set-html-content";
import { t } from "@/i18n-js/instance";
import { useBroadcastReport } from "./BroadcastReportContext";
import { StatsPercentageCard } from "./StatsPercentageCard";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.percentage_stats_fields";

/* Anchor has a default style that sets it to `brand` color. Default it to brand button text for now. */
const tooltipStyle = {
  "--brand": "var(--brand-button-text)",
};

export const StatsPercentageGrid = () => {
  const {
    data: {
      delivered = null,
      opened = null,
      clicked = null,
      hard_bounced: hardBounced = null,
      unsubscribed = null,
      complained = null,
      sent = null,
      recipient_count: recipientCount = null,
    } = {},
  } = useBroadcastReport();

  const total = sent || recipientCount;

  return (
    <div className="mb-6 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      <StatsPercentageCard
        label={t([localeNamespace, "delivered"])}
        value={delivered}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "delivered"])}
      />
      <StatsPercentageCard
        label={t([localeNamespace, "opened"])}
        value={opened}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "opened"])}
            style={tooltipStyle}
          />
        }
      />
      <StatsPercentageCard
        label={t([localeNamespace, "clicked"])}
        value={clicked}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "clicked"])}
      />
      <StatsPercentageCard
        label={t([localeNamespace, "hard_bounced"])}
        value={hardBounced}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "hard_bounced"])}
      />
      <StatsPercentageCard
        label={t([localeNamespace, "unsubscribed"])}
        value={unsubscribed}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "unsubscribed"])}
            style={tooltipStyle}
          />
        }
      />
      <StatsPercentageCard
        label={t([localeNamespace, "marked_as_spam"])}
        value={complained}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "marked_as_spam"])}
            style={tooltipStyle}
          />
        }
      />
    </div>
  );
};
