import { useIsSegmentsEnabled } from "@circle-react/hooks/useIsSegmentsEnabled";
import type { RenderFilter } from "./types";

export const useShouldShowSegmentFilter = (filters: RenderFilter[]) => {
  const isSegmentsEnabled = useIsSegmentsEnabled();

  if (isSegmentsEnabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "segment_id");
};
