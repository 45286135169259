import { t } from "@/i18n-js/instance";
import { DropdownWrapper } from "@circle-react-shared/uikit/HeaderV3/DropdownWrapper";
import { GlobalSearch } from "@circle-react-shared/uikit/HeaderV3/GlobalSearch";
import { HeaderWrapper } from "@circle-react-shared/uikit/HeaderV3/HeaderWrapper";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { ProfileSectionDropdown } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/ProfileSectionDropdown";

export const HeaderV2_1Preview = () => (
  <HeaderWrapper>
    <DropdownWrapper>
      <IconButton
        name="16-keyboard-shortcuts"
        buttonSize={28}
        iconClassName="!text-c-header"
        className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover focus:!bg-c-header-hover rounded-md"
        disabled
        ariaLabel={t("header_v3.keyboard_shortcuts")}
      />
    </DropdownWrapper>
    <GlobalSearch isPreviewMode />
    <DropdownWrapper className="justify-end">
      <IconButton
        name="16-notifications"
        buttonSize={28}
        iconClassName="!text-c-header"
        className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
        disabled
        ariaLabel={t("header_v3.notifications")}
      />
      <IconButton
        name="16-chat"
        buttonSize={28}
        iconClassName="!text-c-header"
        className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
        disabled
        ariaLabel={t("header_v3.direct_messages")}
      />
      <IconButton
        name="16-chat-threads-header"
        buttonSize={28}
        iconClassName="!text-c-header"
        className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
        disabled
        ariaLabel={t("header_v3.chat_threads")}
      />
      <ProfileSectionDropdown isPreviewMode />
    </DropdownWrapper>
  </HeaderWrapper>
);
