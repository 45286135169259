import { isFunction } from "lodash";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { CheckboxFilterParam } from "@circle-react-shared/Filter/FilterTypes/CheckboxFilterV2";
import { Loader } from "@circle-react-uikit/Loader";
import { useSpaceGroupsList } from "./useSpaceGroupsList";

interface SpaceGroupsFilterProps {
  localeNamespace?: string;
  isInitialOpen?: boolean;
  onApply?: (param: CheckboxFilterParam) => void;
  param: CheckboxFilterParam;
  shouldShowRemoveButton?: boolean;
}

/**
 * This is the v2 version filters that is to be used on Members which will support comparators and multiple instances.
 * The v1 version of this filter is shared by other pages, hence created a copy.
 */
export const SpaceGroupsFilter = ({
  localeNamespace = "members_directory.header.search.filters.space_groups",
  isInitialOpen = false,
  onApply,
  param,
  shouldShowRemoveButton = false,
}: SpaceGroupsFilterProps) => {
  const spaceGroupsList = useSpaceGroupsList();
  /** @todo
   * The hook returns an array at a point. This is possibly a bug.
   * To check for usage in other places before refactoring.
   */
  invariant(!Array.isArray(spaceGroupsList), "spaceGroupsList is not an array");
  const { visibleSpaceGroups, isLoading } = spaceGroupsList;

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText
          items={visibleSpaceGroups}
          param={param}
        />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton={shouldShowRemoveButton}
    >
      {isLoading ? (
        <Loader center />
      ) : (
        <SingleSelectFilter
          options={visibleSpaceGroups}
          legend={t([localeNamespace, "singular_legend"])}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
          param={param}
        />
      )}
    </Filter>
  );
};
