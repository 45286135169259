import { t } from "@/i18n-js/instance";

export interface CommunityLogoProps {
  logoURL: string;
  name: string;
}

export const WithLogo = ({ logoURL, name }: CommunityLogoProps) => (
  <div className="community__logo">
    <img
      loading="eager"
      alt={t("community_logo_alt", { community_name: name })}
      src={logoURL}
    />
  </div>
);
