import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.links_table";

export const NoLinksFound = () => (
  <div className="mx-auto flex max-w-sm flex-col py-[70px] text-center">
    <Icon type="20-link" size={20} className="text-default mb-4" />
    <div className="mb-2">
      <Typography.TitleSm weight="semibold" color="text-very-dark">
        {t([localeNamespace, "no_links_found"])}
      </Typography.TitleSm>
    </div>
    <Typography.BodyMd color="text-default">
      {t([localeNamespace, "no_links_found_description"])}
    </Typography.BodyMd>
  </div>
);
