import { Typography } from "@circle-react-shared/uikit/Typography";

export interface BulletedListProps {
  items: string[];
}

export const BulletedList = ({ items }: BulletedListProps) => (
  <div className="p-4">
    <ul className="list-outside list-disc pl-5">
      {items.map(item => (
        <li key={item}>
          <Typography.BodySm>{item}</Typography.BodySm>
        </li>
      ))}
    </ul>
  </div>
);
