import { isObject, isString } from "lodash";
import { t } from "@/i18n-js/instance";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";

const i18nRoot = "filter_comparators";

const comparatorsForString = [
  "is",
  "is_not",
  "contains",
  "does_not_contain",
] as const;

export type ComparatorsForString = (typeof comparatorsForString)[number];

export interface StringComparatorParam extends BaseFilter {
  filter_type: ComparatorsForString;
  value: string;
}

export type StringComparatorProfileFieldParam = StringComparatorParam &
  ProfileFieldBaseFilter;

export const isComparatorsForString = (
  filterType: unknown,
): filterType is ComparatorsForString =>
  isString(filterType) &&
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Check if filterType belongs to Comparators for String
  comparatorsForString.includes(filterType as ComparatorsForString);

export const isStringComparatorParam = (
  selected: BaseFilter,
): selected is StringComparatorParam =>
  isObject(selected) &&
  "filter_type" in selected &&
  isComparatorsForString(selected.filter_type) &&
  "value" in selected;

export const renderTextSelectedItem = (selected: BaseFilter) => {
  if (isStringComparatorParam(selected)) {
    return t(["filter_comparators", "values", selected.filter_type], {
      value: selected.value,
    });
  }
  return null;
};

export const stringComparators: ReadonlyArray<{
  label: string;
  value: ComparatorsForString;
}> = [
  {
    label: t([i18nRoot, "contains"]),
    value: "contains",
  },
  {
    label: t([i18nRoot, "does_not_contain"]),
    value: "does_not_contain",
  },
  {
    label: t([i18nRoot, "is"]),
    value: "is",
  },
  {
    label: t([i18nRoot, "is_not"]),
    value: "is_not",
  },
];
