import { useState } from "react";
import { ChatList } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/ChatList";
import { Header } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/Header";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type { ChatSortType } from "@circle-react/types/CommunityBot/ChatSort";

export const CommunityInboxLinks = () => {
  const [activeSort, setActiveSort] = useState<ChatSortType>("latest");
  const { adminChats: chats } = useCommunityInboxStore();

  const shouldShowHeader = Boolean(chats.length);
  return (
    <div className="block h-full">
      {shouldShowHeader && (
        <Header activeSort={activeSort} setActiveSort={setActiveSort} />
      )}
      <ChatList activeSort={activeSort} />
    </div>
  );
};
