import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { EmailEditorToolbar } from "./Toolbar";

const disabledExtensions = ["mention", "embeds", "poll", "code_block"];
const disabledSlashMenuCategories = ["Embed"];
const disabledSlashMenuCommands = [
  "code",
  "poll",
  "audio",
  "video",
  "pdf",
  "giphy",
];

interface TipTapBlockEditorProps {
  editorRef: RefObject<Editor | null>;
}

export function TipTapEditor({ editorRef }: TipTapBlockEditorProps) {
  const { getValues } = useFormContext();
  const emailContent = getValues("email_content");

  return (
    <div className="mx-auto max-w-screen-sm">
      <FormTipTapBlockEditor
        placeholder={t("email_editor.placeholder")}
        name="email_content.body"
        editorRef={editorRef}
        disabledExtensions={disabledExtensions}
        disabledSlashMenuCommands={disabledSlashMenuCommands}
        disabledSlashMenuCategories={disabledSlashMenuCategories}
        headingLevels={[1, 2, 3]}
        toolbars={[
          {
            portalId: "email-editor-toolbar-portal",
            component: EmailEditorToolbar,
          },
        ]}
        sgidToObjectMap={emailContent.sgids_to_object_map}
        inlineAttachments={emailContent.inline_attachments}
        shouldPreviewFilesAsLinks
      />
    </div>
  );
}
