import type { IconType } from "@circle-react-shared/Icon";

export const TRIGGER_TYPE_TO_ICON: Record<string, IconType> = {
  community_member_joined_community:
    "16-workflows-v2-community-member-joined-community",
  community_member_rsvps_to_event:
    "16-workflows-v2-community-member-rsvps-to-event",
  community_member_rsvps_to_recurring_event:
    "16-workflows-v2-community-member-rsvps-to-recurring-event",
  community_member_joined_space:
    "16-workflows-v2-community-member-joined-space",
  community_member_leaves_space:
    "16-workflows-v2-community-member-leaves-space",
  community_member_joined_space_group:
    "16-workflows-v2-community-member-joined-space-group",
  community_member_leaves_space_group:
    "16-workflows-v2-community-member-leaves-space-group",
  community_member_purchases_paywall:
    "16-workflows-v2-community-member-purchases-paywall",
  contact_tagged: "16-workflows-v2-contact-tagged",
  contact_untagged: "16-workflows-v2-contact-untagged",
  event_ended_for_member: "16-workflows-v2-event-ended-for-member",
  post_published: "16-workflows-v2-post-published",
  post_comment_created: "16-workflows-v2-post-comment-created",
  post_liked: "16-workflows-v2-post-liked",
  community_member_completed_course:
    "16-workflows-v2-community-member-completed-course",
  community_member_completed_lesson:
    "16-workflows-v2-community-member-completed-lesson",
  community_member_completed_section:
    "16-workflows-v2-community-member-completed-section",
  community_member_subscribed_to_paywall:
    "16-workflows-v2-community-member-subscribed-to-paywall",
  community_member_unsubscribed_from_paywall:
    "16-workflows-v2-community-member-unsubscribed-from-paywall",
  community_member_started_paywall_trial:
    "16-workflows-v2-community-member-started-paywall-trial",
  community_member_ended_trial_without_subscription:
    "16-workflows-v2-community-member-ended-trial-without-subscription",
  event_starts_in_an_hour: "16-workflows-v2-event-starts-in-an-hour",
  event_published: "16-workflows-v2-event-published",
  community_member_attended_live_event:
    "16-workflows-v2-community-member-attended-live-event",
  community_member_profile_field_updated:
    "16-workflows-v2-community-member-profile-field-updated",
  community_member_email_changed:
    "16-workflows-v2-community-member-email-changed",
  gamification_member_leveled_up:
    "16-workflows-v2-gamification-member-leveled-up",
  gamification_member_leveled_down: "16-workflows-v2-leveled-down",
  received_form_submission: "16-workflows-v2-received-form-submission",
  community_member_passed_quiz: "16-workflows-v2-community-member-passed-quiz",
  community_member_failed_quiz: "16-workflows-v2-community-member-failed-quiz",
  community_member_submitted_quiz:
    "16-workflows-v2-community-member-submitted-quiz",
  default: "16-workflows-lightning-filled",
};
