import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";

const localeNamespace = "members_directory.header.search.filters";

interface BioFilterProps {
  isInitialOpen?: boolean;
  onApply?: (param: StringComparatorParam) => void;
  param: StringComparatorParam;
}

export const BioFilter = ({
  isInitialOpen,
  onApply,
  param,
}: BioFilterProps) => (
  <Filter
    chip={t([localeNamespace, "bio"])}
    title={t([localeNamespace, "bio_title"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    renderSelectedText={() => renderTextSelectedItem(param)}
    onApply={(param: StringComparatorParam) => {
      if (isFunction(onApply)) {
        onApply(param);
      }
    }}
    shouldShowRemoveButton
  >
    <InputFilterComparator
      name="bio"
      param={param}
      placeholder={t([localeNamespace, "bio_placeholder"])}
    />
  </Filter>
);
